import React from "react";
import Layout from "../components/layout";
import ContactUs from "../components/contactUs";

const contact = () => {
  return (
    <Layout>
      <div
        className="header has-background-primary"
        style={{ height: "150px", width: "100%", position: "relative" }}
      >
        <svg
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: "10vw",
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 2"
          preserveAspectRatio="none"
        >
          <path fill="white" d="M 0 2 Q 12 0 16 2 Z" />
        </svg>
      </div>
      <ContactUs />
    </Layout>
  );
};

export default contact;
